.container {
  display: flex;
  overflow-x: auto;
}
.container img {
  margin-right: 15px;
}
.container::-webkit-scrollbar {
  display: none;
}

.listUlLi li{
  margin-top: 15px;
  margin-bottom: 15px;
}



.button {
  cursor: pointer;
  position: relative;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 380px;
  font-size: 18px;
  font-weight: 600;
  border-radius: 8px;
  overflow: hidden;
  letter-spacing: 0.03em;
  color: #fff;
  background: #01875f;
  border: 1px solid #FFF;
  /* background: linear-gradient(270deg, grey 0%, black 100%); */   
}

/* .button:hover {
  background: repeating-linear-gradient( 
          90deg,
          #101010,
          #101010 10%,
          #404040 10%,
          #404040 20%);
  background-size: 200% 200%;
  animation: BUTTON_BG 2s ease-in-out infinite forwards;
} */

@keyframes BUTTON_BG { 
  0% { background-position: 200% 0}
  100%{ background-position: 0 0}
}

.button:disabled:hover {
animation: none;
background: #101010;
cursor: initial;
}




.loadingOverlay {
position: absolute;
top: 0;
bottom: 0;
right: 0;
width: 100%;
background-color: rgba(255,255,255,0.5);
transform-origin: right;
transition: all 100ms linear;
}

.loadingSpinner {
position: absolute;
width: 16px;
left: 8px;
fill: none;
animation: SVG 1.8s infinite linear;
opacity: 0.6;
}

@keyframes SVG {
0% {
  transform: rotateZ(0deg);
}
100% {
  transform: rotateZ(360deg)
}
}

.loadingSpinner > circle {
stroke: #fff;
stroke-width: 2px;
stroke-linecap: round;
stroke-dasharray: 50;
stroke-dashoffset: 10;
/* Dash of 50px (the circle circumference) */
animation: CIRCLE 1.2s infinite both ease-in-out;
transform-origin: 50% 50%;
}

@keyframes CIRCLE {
0% {
  stroke-dashoffset: 48;
  transform: rotate(0deg);
}
50%, 75% {
  stroke-dashoffset: 10;
  transform: rotate(50deg);
}

100% {
  stroke-dashoffset: 48;
  transform: rotate(360deg);
}
}

